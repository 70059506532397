import React from "react";
import { graphql } from "gatsby";
import { differenceInDays, formatDistance, format } from "date-fns";

import SEO from "../components/seo";
import Container from "../components/container";
import PortableText from "../components/portableText";

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      publishedAt
      mainImage {
        ...SanityImage
        alt
      }
      title
      slug {
        current
      }
      excerpt
      _rawBody(resolveReferences: { maxDepth: 5 })
    }
  }
`;

const BlogPostTemplate = ({ data }) => {
  const post = data?.post;
  if (!post) return null;

  const {
    title = "Untitled post",
    excerpt,
    mainImage,
    _rawBody,
    publishedAt,
  } = post;

  return (
    <>
      <SEO title={title} description={excerpt} image={mainImage} />
      <article className="blog-post">
        <Container noPad>
          <div className="prose dark:prose-invert prose-lg md:py-12">
            <h1>Blog</h1>
            <h2>{title}</h2>
            {publishedAt && (
              <div className="uppercase font-sans text-gray-400 text-xs tracking-wider mb-16">
                Published{" "}
                {differenceInDays(new Date(publishedAt), new Date()) > 3
                  ? formatDistance(new Date(publishedAt), new Date())
                  : format(new Date(publishedAt), "MMMM Mo, yyyy")}
              </div>
            )}
            {_rawBody && <PortableText blocks={_rawBody} />}
          </div>
        </Container>
      </article>
    </>
  );
};

export default BlogPostTemplate;
